import React from "react";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Icon from "antd/es/icon";
import Input from "antd/es/input";
import { printMsg } from "../util/functions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  HIDE_MESSAGE,
  ON_SHOW_LOADER,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SET_CLOUD_ACCOUNT_ID,
  SET_CLOUD_TYPE
} from "constants/ActionTypes";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import { getWhiteLabelPartnerName, getWhiteLabelMainWebsiteUrlBase, getWhiteLabelLogoUrl, getIsWhiteLabelAppPartner } from "util/whitelabel";
import "./SignIn.css"
import { Layout } from "antd";
const { Footer } = Layout;

const FormItem = Form.Item;

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggleModal = event => {
    const { isOpen } = this.state;
    this.setState(
      { isOpen: !isOpen }
    );
    if (this.state.isOpen === true) {
      this.props.neededTrue();
    } else {
      this.props.neededFalse();
    }
  }

  toggleReadOnly = () => {
    this.setState(prevState => ({ readOnly: !prevState.readOnly }));
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (this.props.location.pathname === "/partnersignin") {
        values.partner = true;
      }

      if (!err) {
        try {
          this.props.userSignIn(values);
        } catch (error) {
        }

        this.props.showAuthLoader();
      }
    });
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const from = urlParams.get("from");
    if (from && from === "auditor") {
      window.history.pushState({}, document.title, "/signin");
      printMsg("info", "The Auditor Sign In page has been moved here.", 10)
    }

    this.props.planIdClear();
    this.props.planDetailsClear();
    this.props.cloudAccountIdClear();
    sessionStorage.removeItem("cloudAccountId");
    this.props.cloudTypeClear();
    sessionStorage.removeItem("cloudType");
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showMessage, loader, alertMessage } = this.props;

    return (
      <div
        className="gx-app-login-wrap font-poppins"
        style={{
          backgroundImage: `url(/clouds-sky.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% auto",
          animation: "float 6s ease-in-out infinite",
          backgroundPosition: "bottom",
          '@media (max-width: 480px)': {
            backgroundPosition: "bottom 20%",
          }
        }}
      >
        {/* <img
          src="/rainbow-3.png"
          alt="Rainbow"
          style={{
            position: "absolute",
            bottom: "10%",
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            zIndex: 0,
            opacity: 0.2
          }}
        /> */}
        <img
          src="/ic-zen-dude.svg"
          className="max-sm:hidden"
          alt="Zen Mode"
          style={{
            position: "absolute",
            left: "7%",
            top: "50%",
            transform: "translateY(-50%)",
            width: "14%",
            zIndex: 10
          }}
        />
        <img
          src="https://www.icompaas.com/static/i/form-icon.svg"
          className="max-sm:hidden"
          alt="Form Icon"
          style={{
            position: "absolute",
            top: "3%",
            left: "6%",
            width: "4%",
            zIndex: 2
          }}
        />
        <img
          src="https://images.icompaas.com/i/lock-badge-blue.svg"
          className="max-sm:hidden"
          alt="Lock Badge"
          style={{
            position: "absolute",
            top: "30%",
            right: "5%",
            width: "5%",
            zIndex: 2
          }}
        />
        <img
          src="https://images.icompaas.com/i/dollar-circle-blue.svg"
          className="max-sm:hidden"
          alt="Dollar Circle"
          style={{
            position: "absolute",
            bottom: "2%",
            left: "0%",
            width: "5%",
            zIndex: 2
          }}
        />
        <div className="gx-app-login-container " style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div
                className="gx-app-logo-content-bg bg-blue-300"
              // style={{ backgroundColor: "##000" }}
              ></div>
              <div className="gx-app-logo-wid">
                {this.props.location.pathname === "/partnersignin" ? (
                  <h1> Partner Sign In</h1>
                ) : (
                  <h1 className="font-bold text-3xl">
                    <IntlMessages id="app.userAuth.signIn" />
                  </h1>
                )}

                <p className="text-lg">Your Compliance Journey Starts Here!</p>
              </div>
              <div
                className="gx-app-logo "
                style={{ display: "inline-block", textAlign: "center" }}
              >
                <img
                  alt={getWhiteLabelPartnerName()}
                  src={getWhiteLabelLogoUrl() === "https://images.icompaas.com/i/ic-LogoNtext-Main.png" ? require("assets/images/white-logo.png").default : getWhiteLabelLogoUrl()}
                />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                onSubmit={this.handleSubmit}
                className="gx-signin-form gx-form-row0"
              >
                <FormItem className="gx-py-3" style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        type: "email",
                        message: "Please enter the email"
                      }
                    ]
                  })(
                    <Input
                      placeholder="Email"
                      prefix={
                        <Icon type="user" style={{ color: "DodgerBlue" }} />
                      }
                      size="large"
                      disabled={loader}
                    />
                  )}
                </FormItem>
                <FormItem className="gx-py-3">
                  {getFieldDecorator("password", {
                    rules: [
                      { required: true, message: "Please enter the password" }
                    ]
                  })(
                    <Input
                      prefix={
                        <Icon type="lock" style={{ color: "DodgerBlue" }} />
                      }
                      type="password"
                      placeholder="Password"
                      size="large"
                      disabled={loader}
                    />
                  )}
                </FormItem>

                <Form.Item>
                  <div className="gx-col-flex">
                    <Button
                      // type="primary"
                      htmlType="submit"
                      className="login-form-button !bg-blue-500 !text-white font-medium hover:!bg-white hover:!text-blue-500"
                      loading={loader}
                      block
                    >
                      <IntlMessages id="app.userAuth.signIn" />
                    </Button>
                    <div className="gx-row-flex">
                      <div className="flex space-x-10">
                        {getIsWhiteLabelAppPartner() === false ? (
                          <div style={{ alignContent: "left" }}>
                            <a
                              href={`${getWhiteLabelMainWebsiteUrlBase()}/signup/account/`}
                              target="_blank"
                              className="!text-blue-500 font-medium text-center"
                              rel="noreferrer noopener"
                            >
                              <IntlMessages id="app.userAuth.signUp" />
                            </a>
                          </div>
                        ) : null}

                        <div >
                          <Link
                            className="gx-login-form-forgot !text-blue-500 font-medium"
                            to="/forgotpassword"
                          >
                            {" "}
                            <IntlMessages id="app.userAuth.forgotPassword" />
                          </Link>
                        </div>
                      </div>
                      <hr className="border border-blue-500"/>
                      {getIsWhiteLabelAppPartner() === false ? (
                        <div style={{ alignContent: "left" }}>
                          <a href="/auditor/signup" className="!text-blue-500 font-medium">
                            <IntlMessages id="app.userAuth.auditorSignUp" />
                          </a>
                        </div>
                      ) : null}


                    </div>
                  </div>
                </Form.Item>
              </Form>
            </div>

            {showMessage ? printMsg("error", alertMessage.toString(), 5) : null}
          </div>

          <Footer className="w-full absolute bottom-0 !bg-transparent py-2">
            <div 
              className="absolute inset-0 w-full h-full opacity-65"
              style={{
                backgroundImage: 'url("https://images.icompaas.com/i/dot-bg.svg")',
                // backgroundRepeat: 'no-repeat',
                // backgroundSize: 'cover',
                // backgroundPosition: 'center',
                // transform: 'scale(2.5)',
              }}
            />
            <div className="w-full mx-auto px-4 relative z-10">
              <div className="flex flex-col items-center justify-center gap-1">
                {/* Policies Section */}
                <div className="flex items-center space-x-4">
                  <div className="flex items-center space-x-4">
                    <a 
                      href="https://www.icompaas.com/terms-of-use" 
                      target="_blank" 
                      className="text-gray-600 hover:text-blue-500 transition-colors duration-200 text-xs"
                      rel="noreferrer"
                    >
                      Terms Of Use
                    </a>
                    <a 
                      href="https://www.icompaas.com/privacy-policy" 
                      target="_blank" 
                      className="text-gray-600 hover:text-blue-500 transition-colors duration-200 text-xs"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                    <a 
                      href="https://www.icompaas.com/cookie-policy" 
                      className="text-gray-600 hover:text-blue-500 transition-colors duration-200 text-xs"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Cookie Policy
                    </a>
                  </div>
                </div>

                {/* Copyright */}
                <div className="text-center text-gray-400 text-[10px]">
                  <p>{`Copyright © ${new Date().getFullYear()} All Rights Reserved by iCompaas LLC.`}</p>
                </div>
              </div>
            </div>
          </Footer>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth, CloudAccount, PlanId, PlanDetails }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  const { needed } = CloudAccount;
  const { name } = PlanId;
  const { details } = PlanDetails;
  return { loader, alertMessage, showMessage, authUser, needed, name, details };
};

const mapDispatchToProps = dispatch => {
  return {
    neededTrue: () => dispatch({ type: "neededTrue" }),
    neededFalse: () => dispatch({ type: "neededFalse" }),
    planIdClear: () => dispatch({ type: "PlanIdClear" }),
    planDetailsClear: () => dispatch({ type: "PlanDetailsClear" }),
    cloudAccountIdClear: data => dispatch({ type: SET_CLOUD_ACCOUNT_ID, payload: null }),
    cloudTypeClear: data => dispatch({ type: SET_CLOUD_TYPE, payload: null }),
    userSignIn: user => dispatch({ type: SIGNIN_USER, payload: user }),
    hideMessage: () => dispatch({ type: HIDE_MESSAGE }),
    showAuthLoader: () => dispatch({ type: ON_SHOW_LOADER }),
    userFacebookSignIn: () => dispatch({ type: SIGNIN_FACEBOOK_USER }),
    userGoogleSignIn: () => dispatch({ type: SIGNIN_GOOGLE_USER }),
    userGithubSignIn: () => dispatch({ type: SIGNIN_GITHUB_USER }),
    userTwitterSignIn: () => dispatch({ type: SIGNIN_TWITTER_USER })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WrappedNormalLoginForm);
