import React, { Component } from "react";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Icon from "antd/es/icon";
import IntlMessages from "util/IntlMessages";
import axios from "axios";
import { handleGeneralErrorMessage } from "../util/functions";
import { getWhiteLabelPartnerName, getWhiteLabelLogoUrl } from "util/whitelabel";
import { Layout } from "antd";
import { Link } from "react-router-dom";

const { Footer } = Layout;
const FormItem = Form.Item;

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validEmail: null,
      loading: false
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true })
        this.forgotPassword(values.email);
      }
    });
  };

  async forgotPassword(email) {
    try {
      let res = await axios({
        method: "post",
        headers: {
          "content-type": "application/json"
        },
        url: "/backend/account/forgotPassword",
        data: { email: email }
      });
      if (res && res.status !== "success") {
        this.setState({ validEmail: false });
      } else {
        this.setState({ validEmail: true });
      }
      this.setState({ loading: false })
    } catch (err) {
      this.setState({ loading: false })
      handleGeneralErrorMessage(err);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { validEmail } = this.state;

    return (
      <div
        className="gx-app-login-wrap font-poppins"
        style={{
          backgroundImage: `url(/clouds-sky.png)`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% auto",
          animation: "float 6s ease-in-out infinite"
        }}
      >
        <img
          src="/ic-zen-dude.svg"
          className="max-sm:hidden"
          alt="Zen Mode"
          style={{
            position: "absolute",
            left: "7%",
            top: "50%",
            transform: "translateY(-50%)",
            width: "14%",
            zIndex: 10
          }}
        />
        <img
          src="https://www.icompaas.com/static/i/form-icon.svg"
          className="max-sm:hidden"
          alt="Form Icon"
          style={{
            position: "absolute",
            top: "3%",
            left: "6%",
            width: "4%",
            zIndex: 2
          }}
        />
        <img
          src="https://images.icompaas.com/i/lock-badge-blue.svg"
          className="max-sm:hidden"
          alt="Lock Badge"
          style={{
            position: "absolute",
            top: "30%",
            right: "5%",
            width: "5%",
            zIndex: 2
          }}
        />
        <img
          src="https://images.icompaas.com/i/dollar-circle-blue.svg"
          className="max-sm:hidden"
          alt="Dollar Circle"
          style={{
            position: "absolute",
            bottom: "2%",
            left: "0%",
            width: "5%",
            zIndex: 2
          }}
        />
        <div className="gx-app-login-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <div className="gx-app-login-main-content">
            {validEmail === null ? (
              <>
                <div className="gx-app-logo-content">
                  <div className="gx-app-logo-content-bg bg-blue-300"></div>
                  <div className="gx-app-logo-wid">
                    <h1 className="font-bold text-3xl">Forgot Your Password?</h1>
                    <p className="text-sm pt-2 pb-2">
                      <IntlMessages id="app.userAuth.forgot" />
                    </p>
                  </div>
                  <div className="gx-app-logo" style={{ display: "inline-block", textAlign: "center" }}>
                    <img
                      alt={getWhiteLabelPartnerName()}
                      src={getWhiteLabelLogoUrl() === "https://images.icompaas.com/i/ic-LogoNtext-Main.png" ? require("assets/images/white-logo.png").default : getWhiteLabelLogoUrl()}
                    />
                  </div>
                </div>
                <div className="gx-app-login-content">
                  <div className="mb-2">
                    <h2 className="text-2xl font-bold text-gray-800">Forgot Your Password?</h2>
                    {/* <p className="text-gray-600 mt-2">
                      <IntlMessages id="app.userAuth.forgot" />
                    </p> */}
                  </div>
                  <Form
                    layout="vertical"
                    onSubmit={this.handleSubmit}
                    className="gx-signin-form gx-form-row0"
                  >
                    <FormItem className="gx-py-3">
                      {getFieldDecorator("email", {
                        rules: [
                          {
                            type: "email",
                            message: "The input is not a valid email"
                          },
                          {
                            required: true,
                            message: "Please input your email"
                          }
                        ]
                      })(
                        <Input
                          prefix={<Icon type="mail" style={{ color: "DodgerBlue" }} />}
                          type="email"
                          placeholder="Email Address"
                          size="large"
                        />
                      )}
                    </FormItem>

                    <FormItem>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={this.state.loading}
                        className="login-form-button !bg-blue-500 !text-white font-medium hover:!bg-white hover:!text-blue-500"
                        block
                      >
                        <IntlMessages id="app.userAuth.send" />
                      </Button>
                    </FormItem>
                  </Form>
                </div>
              </>
            ) : validEmail === true ? (
              <div className="bg-white/95 backdrop-blur-sm rounded-2xl shadow-xl p-8 max-w-md w-full mx-auto text-center">
                <div className="mb-6">
                  <div className="w-16 h-16 bg-green-100 rounded-full mx-auto flex items-center justify-center mb-4">
                    <svg className="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                  </div>
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">Check Your Email</h3>
                  <p className="text-gray-600">
                    Please check your Inbox or Spam folder for the password reset link email.
                  </p>
                </div>
                <Link
                  to="/signin"
                  className="inline-flex items-center text-blue-500 hover:text-blue-600 transition-colors"
                >
                  <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                  </svg>
                  Back to Sign In
                </Link>
              </div>
            ) : (
              <div className="bg-white/95 backdrop-blur-sm rounded-2xl shadow-xl max-w-md w-full mx-auto text-center overflow-hidden">
                <div className="bg-blue-500 p-8 mb-6">
                  <div className="w-16 h-16 bg-white/25 rounded-full mx-auto flex items-center justify-center mb-4">
                    <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z">
                      </path>
                    </svg>
                  </div>
                  <h3 className="text-3xl font-semibold text-white mb-2">Email Sent</h3>
                </div>

                <div className="px-8 pb-8">
                  <p className="text-gray-600 text-[16px] mb-6">
                    If your email id is associated with us, an email will be sent to you shortly with instructions on how to reset the password.
                  </p>
                  <Link
                    to="/signin"
                    className="inline-flex items-center text-blue-500 hover:text-blue-600 transition-colors group"
                  >
                    <svg className="w-4 h-4 mr-2 transform transition-transform group-hover:-translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
                    </svg>
                    Back to Sign In
                  </Link>
                </div>
              </div>
            )}
          </div>

          <Footer className="w-full absolute bottom-0 !bg-transparent py-2">
            <div
              className="absolute inset-0 w-full h-full opacity-65"
              style={{
                backgroundImage: 'url("https://images.icompaas.com/i/dot-bg.svg")'
              }}
            />
            <div className="w-full mx-auto px-4 relative z-10">
              <div className="flex flex-col items-center justify-center gap-1">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center space-x-4">
                    <a
                      href="https://www.icompaas.com/terms-of-use"
                      target="_blank"
                      className="text-gray-600 hover:text-blue-500 transition-colors duration-200 text-xs"
                      rel="noreferrer"
                    >
                      Terms Of Use
                    </a>
                    <a
                      href="https://www.icompaas.com/privacy-policy"
                      target="_blank"
                      className="text-gray-600 hover:text-blue-500 transition-colors duration-200 text-xs"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                    <a
                      href="https://www.icompaas.com/cookie-policy"
                      className="text-gray-600 hover:text-blue-500 transition-colors duration-200 text-xs"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Cookie Policy
                    </a>
                  </div>
                </div>
                <div className="text-center text-gray-400 text-[10px]">
                  <p>{`Copyright © ${new Date().getFullYear()} All Rights Reserved by iCompaas LLC.`}</p>
                </div>
              </div>
            </div>
          </Footer>
        </div>
      </div>
    );
  }
}

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

export default WrappedForgotPasswordForm;
