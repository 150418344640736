import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Microsoft365Details = ({
    onSectionVisible,
    onConnectionSuccess,
    completedSteps,
    onStepComplete
}) => {
    const [collapsedSteps, setCollapsedSteps] = useState({
        step1: false,
        step2: false,
        step3: false
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTestingConnection, setIsTestingConnection] = useState(false);
    const [testSuccess, setTestSuccess] = useState(false);
    const [showInitialSuccess, setShowInitialSuccess] = useState(false);
    const [helloMessage, setHelloMessage] = useState(null);
    const [users, setUsers] = useState([]);
    const [isLoadingUsers, setIsLoadingUsers] = useState(false);

    useEffect(() => {
        // Check for success parameter in URL after redirect
        const urlParams = new URLSearchParams(window.location.search);
        const success = urlParams.get('success');
        const provider = urlParams.get('provider');

        if (success === 'true' && provider === 'microsoft') {
            setIsModalOpen(true);
            // Clean up URL
            window.history.replaceState({}, '', window.location.pathname);
        }
    }, []);

    const handleMicrosoftLogin = async () => {
        try {
            console.log('Fetching hello message');
            const response = await axios.get('/backend/microsoft/hello', {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache'
                }
            });

            console.log('Response:', response.data);
            setHelloMessage(response.data.message);
            setIsModalOpen(true);
            setShowInitialSuccess(true);
        } catch (error) {
            console.error('Error fetching hello message:', error);
            setHelloMessage('Failed to connect to Microsoft service');
        }
    };

    const handleTestConnection = () => {
        setIsTestingConnection(true);
        setShowInitialSuccess(false);

        // Simulate API call with setTimeout
        setTimeout(() => {
            setTestSuccess(true);
            setIsTestingConnection(false);
            onConnectionSuccess('icompaas');
            setIsModalOpen(false);

            // Get the status panel element and scroll to it
            const statusPanel = document.querySelector('[data-testid="status-panel"]');
            if (statusPanel) {
                statusPanel.scrollIntoView({ behavior: 'smooth' });
            }
        }, 5000); // Changed to 5 seconds to match GSuite
    };

    useEffect(() => {
        const fetchUsers = async () => {
            if (testSuccess) {
                setIsLoadingUsers(true);
                try {
                    const response = await axios.get('/backend/personnelOnboarding/users');
                    setUsers(response.data.users || []);
                } catch (error) {
                    console.error('Error fetching Microsoft 365 users:', error);
                } finally {
                    setIsLoadingUsers(false);
                }
            }
        };

        fetchUsers();
    }, [testSuccess]);

    const StepContent = ({ stepId, title, children }) => (
        <div id={stepId} className="border-b border-gray-200 pb-6">
            <div className="flex justify-between items-center">
                <h4 className="text-lg font-medium text-gray-900 mb-3">
                    {title}
                    {completedSteps[stepId] && (
                        <i className="fas fa-check-circle text-green-500 ml-2" />
                    )}
                </h4>
            </div>
            <div className={`transition-all duration-300 ${collapsedSteps[stepId] ? 'h-0 overflow-hidden' : ''}`}>
                {children}
            </div>
        </div>
    );

    return (
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <div id="overview">
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                    Microsoft 365 Integration with iCompaas
                </h3>
                <p className="text-gray-600 mb-6">
                    Integrate Microsoft 365 with iCompaas to enable automated compliance monitoring and user management. Follow these steps to connect your Microsoft 365 account.
                </p>

                <div className="space-y-8">
                    <StepContent
                        stepId="step1"
                        title="Step 1. Integrate with Microsoft 365"
                    >
                        <p className="text-gray-600 mb-4">
                            On the homepage under Personnel Onboarding:
                        </p>
                        <ul className="list-disc list-inside text-gray-600 space-y-2 mb-4">
                            <li>Choose Microsoft 365</li>
                            <li>You will see a "Login with Microsoft 365" button</li>
                            <li>Click the "Login with Microsoft 365" button to initiate the authentication process</li>
                        </ul>
                        <button
                            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#0078D4] hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            onClick={handleMicrosoftLogin}
                        >
                            <img src="/microsoft.svg" alt="" className="w-5 h-5 mr-2" />
                            Login with Microsoft 365
                        </button>
                    </StepContent>

                    <StepContent
                        stepId="step2"
                        title="Step 2. Redirect to Microsoft Authentication"
                    >
                        <p className="text-gray-600 mb-4">
                            After clicking the login button, you will be redirected to the Microsoft 365 authentication page.
                        </p>
                        <ul className="list-disc list-inside text-gray-600 space-y-2 mb-4">
                            <li>You will be prompted to enter your Microsoft 365 credentials (email and password)</li>
                        </ul>
                    </StepContent>

                    <StepContent
                        stepId="step3"
                        title="Step 3. Grant Permissions"
                    >
                        <p className="text-gray-600 mb-4">
                            Once you log in, you will be asked to grant iCompaas permission to access your Microsoft 365 user data.
                        </p>
                        <p className="text-gray-600 mb-2">
                            Review the permissions requested, which include:
                        </p>
                        <ul className="list-disc list-inside text-gray-600 space-y-2 mb-4">
                            <li>Reading user profiles</li>
                            <li>Accessing directory data</li>
                            <li>Reading Multi-Factor Authentication (MFA) settings</li>
                        </ul>
                        <p className="text-gray-600 mb-4">
                            Click "Accept" to grant the necessary permissions.
                        </p>
                    </StepContent>
                </div>
            </div>

            {/* Add Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl">
                        <div className="text-center">
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                <svg className="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <h3 className="mt-4 text-lg font-medium text-gray-900">
                                {helloMessage || 'Microsoft Login Successful!'}
                            </h3>
                            <p className="mt-2 text-sm text-gray-600 mb-6">
                                Please test your connection to complete the integration.
                            </p>
                            <button
                                onClick={handleTestConnection}
                                disabled={isTestingConnection}
                                className="w-full inline-flex items-center justify-center px-6 py-3 bg-[#0078D4] text-white rounded-lg hover:bg-blue-700 transition-colors disabled:bg-blue-400"
                            >
                                {isTestingConnection ? (
                                    <>
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Testing connection...
                                    </>
                                ) : (
                                    'Test my connection'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Microsoft365Details; 