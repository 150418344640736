import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const PersonnelList = (props) => {
    const { domain } = props.match.params;
    const { lastRetrievalDate } = props.location.state || {};
    const [personnel, setPersonnel] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isRetrieving, setIsRetrieving] = useState(false);

    const handleRetrieveClick = () => {
        setIsRetrieving(true);
        setTimeout(() => {
            setIsRetrieving(false);
            // Refresh the data
            if (domain) {
                fetchPersonnel();
            }
        }, 2000);
    };

    useEffect(() => {
        const fetchPersonnel = async () => {
            setIsLoading(true);
            try {
                await new Promise(resolve => setTimeout(resolve, 1500));
                
                // Example data with new fields
                setPersonnel([
                    {
                        id: 1,
                        name: 'John Doe',
                        email: `john@${domain}`,
                        primaryEmail: `john@${domain}`,
                        phoneNumber: '+1 (555) 123-4567',
                        userCreationTime: '2024-01-15T10:30:00Z',
                        isAdmin: true,
                        is2FAEnforced: true,
                        is2FAEnrolled: true,
                        recoveryEmail: 'john.recovery@gmail.com',
                        isAccountSuspended: false,
                        role: 'Admin'
                    },
                    {
                        id: 2,
                        name: 'Jane Smith',
                        email: `jane@${domain}`,
                        primaryEmail: `jane@${domain}`,
                        phoneNumber: '+1 (555) 234-5678',
                        userCreationTime: '2024-01-16T09:15:00Z',
                        isAdmin: false,
                        is2FAEnforced: true,
                        is2FAEnrolled: true,
                        recoveryEmail: 'jane.recovery@gmail.com',
                        isAccountSuspended: false,
                        role: 'User'
                    },
                    {
                        id: 3,
                        name: 'Mike Johnson',
                        email: `mike@${domain}`,
                        primaryEmail: `mike@${domain}`,
                        phoneNumber: '+1 (555) 345-6789',
                        userCreationTime: '2024-01-17T14:45:00Z',
                        isAdmin: false,
                        is2FAEnforced: true,
                        is2FAEnrolled: false,
                        recoveryEmail: 'mike.recovery@gmail.com',
                        isAccountSuspended: true,
                        role: 'User'
                    },
                    {
                        id: 4,
                        name: 'Sarah Wilson',
                        email: `sarah@${domain}`,
                        primaryEmail: `sarah@${domain}`,
                        phoneNumber: '+1 (555) 456-7890',
                        userCreationTime: '2024-01-18T11:20:00Z',
                        isAdmin: true,
                        is2FAEnforced: true,
                        is2FAEnrolled: true,
                        recoveryEmail: 'sarah.recovery@gmail.com',
                        isAccountSuspended: false,
                        role: 'Super Admin'
                    },
                    {
                        id: 5,
                        name: 'David Brown',
                        email: `david@${domain}`,
                        primaryEmail: `david@${domain}`,
                        phoneNumber: '+1 (555) 567-8901',
                        userCreationTime: '2024-01-19T16:00:00Z',
                        isAdmin: false,
                        is2FAEnforced: false,
                        is2FAEnrolled: false,
                        recoveryEmail: 'david.recovery@gmail.com',
                        isAccountSuspended: false,
                        role: 'User'
                    },
                    // Add more mock data as needed
                ]);
            } catch (error) {
                console.error('Error fetching personnel:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (domain) {
            fetchPersonnel();
        }
    }, [domain]);

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString();
    };

    const BooleanIndicator = ({ value }) => (
        <span className={`px-2 py-1 text-xs font-medium rounded-full ${
            value ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
        }`}>
            {value ? 'Yes' : 'No'}
        </span>
    );

    return (
        <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-[95%] mx-auto">
                <div className="mb-8">
                    <div className="flex items-center space-x-2">
                        <h1 className="text-3xl font-semibold text-gray-900">
                            Personnel List for {domain}
                        </h1>
                    </div>
                    <p className="mt-2 text-sm text-gray-600">
                        Manage and view all personnel associated with your Google Workspace domain
                    </p>
                    <div className="mt-4 flex items-center gap-4">
                        {lastRetrievalDate && (
                            <p className="text-sm text-gray-500">
                                Last Synced: {new Date(lastRetrievalDate).toLocaleString()}
                            </p>
                        )}
                        <button
                            onClick={handleRetrieveClick}
                            disabled={isRetrieving}
                            className={`py-2 px-4 mt-[-30px] rounded-lg border flex items-center justify-center gap-2 transition-all duration-300 
                                ${isRetrieving 
                                    ? 'bg-gray-100 text-gray-400 border-gray-200 cursor-not-allowed'
                                    : 'bg-blue-50 text-blue-700 border-blue-300 hover:bg-blue-100'
                                }`}
                        >
                            {isRetrieving ? (
                                <>
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Retrieving...
                                </>
                            ) : (
                                <>
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                    </svg>
                                    Retrieve Personnel List
                                </>
                            )}
                        </button>
                    </div>
                </div>

                {isLoading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                    </div>
                ) : (
                    <div className="bg-white shadow-sm rounded-lg">
                        <div className="max-h-[70vh] overflow-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Primary Email</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone Number</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Creation Time</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Admin</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">2FA Enforced</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">2FA Enrolled</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Recovery Email</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Suspended</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {personnel.map((person) => (
                                        <tr key={person.id}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{person.name}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.primaryEmail}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.phoneNumber}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatDate(person.userCreationTime)}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <BooleanIndicator value={person.isAdmin} />
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <BooleanIndicator value={person.is2FAEnforced} />
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <BooleanIndicator value={person.is2FAEnrolled} />
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.recoveryEmail}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <BooleanIndicator value={person.isAccountSuspended} />
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.role}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withRouter(PersonnelList);