import React, { Component } from "react";
import Layout from "antd/es/layout";
import CloudAccountSelector from "../CloudAccountSelector";
import TimeoutWarning from "../../util/TimeoutWarning";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
import UserInfo from "components/UserInfo";
import Auxiliary from "util/Auxiliary";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import { connect } from "react-redux";
import "styles/pages/topbar.css";
import UserProfile from "../Sidebar/UserProfile";
import "styles/pages/breadcrumb.css";
import { NavLink, useLocation, withRouter } from "react-router-dom";
import HipaaImage from "../../assets/images/HIPAA.jpeg";
import CisImage from "../../assets/images/CIS.jpg";
import Soc1Image from "../../assets/images/soc15.png";
import Soc2Image from "../../assets/images/soc2/soc25.png";
import IsoImage from "../../assets/images/ISO27001.jpg";
import NIST171Image from "../../assets/images/nist171.png";
import NIST853Image from "../../assets/images/nist853.png";
import AwsWaImage from "../../assets/images/AWS_WA_ICON_2_BLUE.png";
import PentestImage from "../../assets/images/pen/webapp_pen_test.jpg";
import axios from "axios";
import SecurityTabs from 'components/SecurityTabs';
const { Header } = Layout;

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_type: null,
      searchText: "",
      windowWidth: window.innerWidth,
      currentPath: window.location.pathname,
      purchasedAddons: [],
      cloudAccountList: []
    };
  }

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={e => this.props.switchLanguage(language)}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  )

  updateSearchChatUser = evt => {
    this.setState({
      searchText: evt.target.value
    });
  }

  async componentDidMount() {
    window.addEventListener('resize',
      () => this.setState({ windowWidth: window.innerWidth })
    );

    // Update currentPath when location changes
    this.unlisten = this.props.history.listen((location) => {
      this.setState({ currentPath: location.pathname });
    });

    this.fetchPurchasedAddons();

    // Fetch cloud accounts
    try {
      const cloudAccountRes = await axios.get("/backend/account/cloudAccounts");
      const cloudAccountList = cloudAccountRes.data;
      this.setState({ cloudAccountList });
    } catch (error) {
      console.error("Error fetching cloud accounts:", error);
    }

    // Handle initial path for security tabs
    const currentPath = window.location.pathname;
    if (currentPath.includes('/portal/compliance/cislevel1') ||
      currentPath.includes('/portal/compliance/cislevel2') ||
      currentPath.includes('/portal/compliance/awswellarch') ||
      currentPath.includes('/portal/compliance/security/')) {

      this.props.updateSidebarKeys(['security']);

      this.props.dispatch({
        type: 'SET_MENU_STATE',
        payload: {
          openKeys: ['security'],
          selectedKeys: [currentPath.replace('/portal/', '')]
        }
      });
    }

    try {
      const response = await axios.get("/backend/constant/getUserType");
      this.setState({ user_type: response.data });
    } catch (error) {
      console.error("Error fetching user type:", error);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setState({ windowWidth: window.innerWidth }));
    // Clean up the history listener
    if (this.unlisten) {
      this.unlisten();
    }
    // Cancel any pending state updates
    this.setState = () => { };
    // Clear any timeouts or intervals if you have any
  }

  handleTabClick = (path) => {
    this.setState({ currentPath: path });

    if (this.props.navCollapsed) {
      this.props.toggleCollapsedSideNav(false);
    }

    // For security posture and other security-related paths
    if (path.includes('/portal/compliance/security') ||
      path.includes('/portal/compliance/cislevel1') ||
      path.includes('/portal/compliance/cislevel2') ||
      path.includes('/portal/compliance/awswellarch')) {

      // Keep security menu open and update selected keys
      this.props.updateSidebarKeys(['security']);

      this.props.dispatch({
        type: 'SET_MENU_STATE',
        payload: {
          openKeys: ['security'],
          selectedKeys: [path.replace('/portal/', '')]
        }
      });
    } else if (path.includes('/portal/compliance/')) {
      // Keep compliance menu open and update selected keys
      this.props.updateSidebarKeys(['compliance']);

      this.props.dispatch({
        type: 'SET_MENU_STATE',
        payload: {
          openKeys: ['compliance'],
          selectedKeys: [path.replace('/portal/', '')]
        }
      });
    }
  }

  fetchPurchasedAddons = async () => {
    try {
      const response = await axios.get("/backend/billing/details");
      if (response.data && response.data.length > 0) {
        const processedAddons = response.data.map(addon => {
          // Special handling for CIS levels
          if (addon.feature_id === 'cis') {
            return [{
              ...addon,
              feature_id: 'cislevel1',
              original_feature_id: 'cislevel1'
            }, {
              ...addon,
              feature_id: 'cislevel2',
              original_feature_id: 'cislevel2'
            }];
          }
          // Handle pentest case
          if (addon.feature_id === 'pentest') {
            return {
              ...addon,
              feature_id: 'pen_test'
            };
          }
          return addon;
        });

        // Flatten the array since CIS creates nested arrays
        const flattenedAddons = processedAddons.flat();
        this.setState({ purchasedAddons: flattenedAddons });
      }
    } catch (error) {
      console.error("Error fetching purchased addons:", error);
    }
  }

  // Add this method to check for available unpurchased addons
  hasUnpurchasedAddons = (allTabs, purchasedTabs) => {
    return allTabs.length > purchasedTabs.length;
  };

  // Change from const to class method
  shouldHideAddonTabs = (currentPath) => {
    return currentPath.includes('/account/cloudaccounts') ||
      currentPath.includes('/account/employee-onboarding') ||
      currentPath.includes('/account/onboarding');
  };

  render() {
    const { navCollapsed, navStyle, cloudType } = this.props;
    const { windowWidth, currentPath, purchasedAddons, user_type, cloudAccountList } = this.state;


    //for testing clouds accounts.
    // console.log('Cloud Account List:', this.props.cloudAccountList);
    // console.log('Cloud Account List Length:', this.props.cloudAccountList ? this.props.cloudAccountList.length : 'No list');

    let addonTabs = [
      {
        id: "summary",
        title: "Compliance Summary",
        path: "/portal/compliance/summary",
        icon: "/compliant-2.svg"
      },
      {
        id: "soc2",
        title: "SOC2 Readiness",
        path: "/portal/compliance/soc2",
        icon: Soc2Image
      },
      {
        id: "hipaa",
        title: "HIPAA Readiness",
        path: "/portal/compliance/hipaa",
        icon: HipaaImage
      },
      {
        id: "iso27001",
        title: "ISO27001 Readiness",
        path: "/portal/compliance/iso27001",
        icon: IsoImage
      }
    ];

    // Only add NIST tabs if cloudType is not azure
    if (cloudType !== 'azure') {
      addonTabs = [
        ...addonTabs,
        {
          id: "nist171",
          title: "NIST 800-171 Readiness",
          path: "/portal/compliance/nist171",
          icon: NIST171Image
        },
        {
          id: "nist853",
          title: "NIST 800-53 Readiness",
          path: "/portal/compliance/nist853",
          icon: NIST853Image
        }
      ];
    }

    // Modified filter to handle the Security Posture tab
    const purchasedAddonTabs = addonTabs.filter(tab => {
      // Show Security Posture tab only on dashboard
      if (tab.id === 'security') {
        return currentPath === '/portal/dashboard';
      }

      // Always include summary tab
      if (tab.id === 'summary') {
        return true;
      }

      return this.state.purchasedAddons.some(addon => {
        if (tab.id === 'cis') {
          return addon.feature_id === tab.level ||
            (addon.feature_id === 'cis' && (tab.level === 'cislevel1' || tab.level === 'cislevel2'));
        }
        if (tab.id === 'pen_test') {
          return addon.feature_id === 'pen_test' || addon.feature_id === 'pentest';
        }
        return addon.feature_id === tab.id;
      });
    });

    return (
      <Auxiliary>
        <Header className="no-wrap">
          {navStyle === NAV_STYLE_DRAWER ||
            ((navStyle === NAV_STYLE_FIXED ||
              navStyle === NAV_STYLE_MINI_SIDEBAR) &&
              windowWidth < TAB_SIZE) ? (
            <div className="gx-linebar d-flex justify-content-center align-items-center" >
              <i
                className="gx-icon-btn icon icon-menu "
                onClick={() => {
                  this.props.toggleCollapsedSideNav(!navCollapsed);
                }}
              />
            </div>
          ) : null}
          {window.innerWidth < "400" ?
            <CloudAccountSelector width="280px" style={{ display: "inline-block" }} />
            :
            <CloudAccountSelector width="85%" style={{ display: "inline-block" }} />
          }
          <ul style={{ display: "inline-block" }} className="gx-header-notifications gx-ml-auto ">
            {windowWidth >= TAB_SIZE ? null : (
              <Auxiliary>
                <li className="gx-user-nav">
                  <UserInfo />
                </li>
              </Auxiliary>
            )}
          </ul>
          <div style={{ marginTop: '20px' }}>
            {windowWidth >= TAB_SIZE && <UserProfile />}

          </div>
        </Header>
        <TimeoutWarning />


        {currentPath === '/account/cloudaccounts' ? (
          <div className="flex flex-col items-center justify-center w-full gap-4 p-4">
            <div className="flex flex-col items-start justify-start w-full gap-4">
              <div className="flex items-center gap-4">
                <button className="inline-flex items-center justify-center h-14 px-6 bg-white border-2 border-blue-500 text-blue-500 hover:!text-white hover:!bg-blue-500 rounded-lg transition-all duration-300 shadow-sm w-64 transform hover:scale-105 hover:shadow-lg group">
                  <svg className="w-6 h-6 mr-3 transition-transform duration-300 group-hover:rotate-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                  </svg>
                  Cloud Onboarding
                </button>

                <svg className="w-6 h-6 text-blue-500 mx-2 font-bold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>

                <NavLink to="/account/cloudaccountsetup?type=aws"
                  className="inline-flex items-center justify-center h-12 px-6 bg-[#FF9900] text-white rounded-lg shadow-sm w-48 hover:bg-[#ec8f00] transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:translate-x-1">
                  <img src="/aws-on-b-2.svg" className="w-10 h-10" alt="AWS icon" />
                </NavLink>

                <NavLink to="/account/cloudaccountsetup?type=azure"
                  className="inline-flex items-center justify-center h-12 px-6 bg-[#0078D4] text-white rounded-lg shadow-sm w-48 hover:bg-[#006cbd] transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:translate-x-1">
                  <img src="/azure-on-b.svg" className="w-7 h-7 mr-1" alt="Azure icon" />
                  Azure
                </NavLink>

                <div className="relative w-64">
                  <button className="inline-flex items-center justify-center h-12 px-6 bg-gradient-to-r from-gray-300 to-gray-500 text-white rounded-lg shadow-sm w-48 cursor-not-allowed transition-all duration-300 hover:opacity-80">
                    <img src="/gcp-on-b.svg" className="w-7 h-7 mr-2" alt="GCP icon" />
                    <span>Google Cloud</span>
                  </button>
                  <span className="absolute right-[66px] top-[-12px] transform -translate-y-1/2 bg-gray-700 text-white text-xs px-2 py-1 rounded animate-bounce">
                    Coming Soon
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {currentPath.includes('/account/emp-on-b-removed-here') ? (
              <div className="flex fancy-tab-container flex-col items-center justify-center w-full gap-4 p-4">
                <div className="flex flex-col items-start justify-start w-full gap-4">
                  <div className="flex items-center gap-4">
                    <button className="inline-flex items-center justify-center cursor-text h-14 px-6 bg-white border-2 border-blue-500 text-blue-500 hover:!text-white hover:!bg-blue-500 rounded-lg transition-all duration-300 shadow-sm w-64 transform hover:scale-105 hover:shadow-lg">
                      <svg className="w-5 h-5 mr-3 transition-transform duration-300 group-hover:rotate-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                      </svg>
                      Personnel Onboarding
                    </button>

                    <svg className="w-6 h-6 text-blue-500 mx-2 font-bold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>

                    <NavLink to="/user/onboardingprocess/gsuite"
                      className="inline-flex items-center justify-center h-12 px-6 bg-blue-600 text-white rounded-lg shadow-sm w-48 hover:bg-blue-700 transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:translate-x-1">
                      <img src="/google.svg" className="w-6 h-6 mr-2 transition-transform duration-300 hover:rotate-12" alt="Google icon" />
                      GSuite
                    </NavLink>

                    <NavLink to="/account/employee-onboarding?integration=microsoft"
                      className="inline-flex items-center justify-center h-12 px-6 bg-[#0078D4] text-white rounded-lg shadow-sm w-48 hover:bg-[#006cbd] transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:translate-x-1">
                      <img src="/microsoft.svg" className="w-6 h-6 mr-2" alt="Microsoft 365 icon" />
                      <span>Microsoft 365</span>
                    </NavLink>
                  </div>
                  <div className="flex items-center gap-6">
                    <button className="inline-flex items-center justify-center cursor-text h-14 px-6 bg-white border-2 border-blue-500 text-blue-500 hover:!text-white hover:!bg-blue-500 rounded-lg transition-all duration-300 shadow-sm w-64 transform hover:scale-105 hover:shadow-lg">
                      <svg className="w-5 h-5 mr-3 transition-transform duration-300 group-hover:rotate-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                      </svg>
                      Personnel Onboarding
                    </button>

                    <svg className="w-6 h-6 text-blue-500 mx-2 font-bold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>

                    <NavLink to="/account/employee-onboarding?integration=google"
                      className="inline-flex items-center justify-center h-12 px-6 bg-blue-600 text-white rounded-lg shadow-sm w-48 hover:bg-blue-700 transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:translate-x-1">
                      <img src="/google.svg" className="w-6 h-6 mr-2 transition-transform duration-300 hover:rotate-12" alt="Google icon" />
                      GSuite
                    </NavLink>

                    <NavLink to="/account/employee-onboarding?integration=microsoft"
                      className="inline-flex items-center justify-center h-12 px-6 bg-[#0078D4] text-white rounded-lg shadow-sm w-48 hover:bg-[#006cbd] transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:translate-x-1">
                      <img src="/microsoft.svg" className="w-6 h-6 mr-2" alt="Microsoft 365 icon" />
                      <span>Microsoft 365</span>
                    </NavLink>
                  </div>


                </div>

                <div className="flex items-center justify-center w-full p-6 bg-blue-50 text-blue-700 rounded-lg mt-6">
                  <div className="text-center">
                    <p className="mb-4 text-sm">No compliance modules are currently active.</p>
                    <NavLink
                      to="/account/addons"
                      className="inline-flex items-center px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors shadow-sm"
                    >
                      <span className="text-sm font-medium">Explore Available Modules</span>
                      <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                      </svg>
                    </NavLink>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {currentPath.includes('/portal/compliance/security') ||
                  (currentPath.includes('/portal/compliance/cislevel1') ||
                    currentPath.includes('/portal/compliance/cislevel2') ||
                    currentPath.includes('/portal/compliance/awswellarch')) && cloudType === "aws" ? (
                  <SecurityTabs
                    currentPath={currentPath}
                    purchasedAddons={purchasedAddons}
                    handleTabClick={this.handleTabClick}
                    cloudType={this.props.cloudType}
                  />
                ) : (
                  <div className="fancy-tab-container">
                    {(!this.shouldHideAddonTabs(currentPath) && purchasedAddonTabs.length > 0) && (
                      <div className="fancy-tabs">
                        {/* First render purchased addon tabs until Compliance Summary */}
                        {purchasedAddonTabs.map((menu) => (
                          menu.id === 'summary' && (
                            <div
                              className={`tab-item ${currentPath === menu.path ? 'active' : ''}`}
                              key={menu.title}
                            >
                              <NavLink
                                to={menu.path}
                                className="tab-link"
                                onClick={() => this.handleTabClick(menu.path)}
                              >
                                <img
                                  src={menu.icon}
                                  alt={menu.title}
                                  className="custom-tab-icon"
                                  style={{ width: '28px', height: '28px' }}
                                />
                                <span className="tab-title md:text-[16px]">{menu.title}</span>
                              </NavLink>
                            </div>
                          )
                        ))}

                        {/* Then render Security Posture tab if on dashboard */}
                        {currentPath === '/portal/dashboard' && (
                          <div className={`tab-item ${currentPath === '/portal/compliance/security' ? 'active' : ''}`}>
                            <NavLink
                              to="/portal/compliance/security"
                              className="tab-link"
                              onClick={() => this.handleTabClick('/portal/compliance/security')}
                            >
                              <img
                                src="/cyber-security.svg"
                                alt="Security Posture"
                                className="custom-tab-icon"
                                style={{ width: '28px', height: '28px' }}
                              />
                              <span className="tab-title md:text-[16px]">Security Posture</span>
                            </NavLink>
                          </div>
                        )}

                        {/* Finally render remaining addon tabs */}
                        {purchasedAddonTabs.map((menu) => (
                          menu.id !== 'summary' && (
                            <div
                              className={`tab-item ${currentPath === menu.path ? 'active' : ''}`}
                              key={menu.title}
                            >
                              <NavLink
                                to={menu.path}
                                className="tab-link"
                                onClick={() => this.handleTabClick(menu.path)}
                              >
                                <img
                                  src={menu.icon}
                                  alt={menu.title}
                                  className="custom-tab-icon"
                                  style={{ width: '28px', height: '28px' }}
                                />
                                <span className="tab-title md:text-[16px]">{menu.title}</span>
                              </NavLink>
                            </div>
                          )
                        ))}

                        {/* Add More Addons tab if there are unpurchased addons */}
                        {this.hasUnpurchasedAddons(addonTabs, purchasedAddonTabs) &&
                          user_type !== null &&
                          typeof user_type !== "undefined" &&
                          user_type !== "subuser" && (
                            <div className="tab-item">
                              <NavLink
                                to="/account/addons"
                                className="tab-link hover:from-blue-100 hover:to-blue-200"
                              >
                                <span className="tab-icon">
                                  <svg
                                    className="w-7 h-7 text-blue-500"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                    />
                                  </svg>
                                </span>
                                <span className="tab-title md:text-[16px] font-medium">
                                  More Addons
                                </span>
                              </NavLink>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ settings, PlanId, PlanDetails, cloudType, cloudAccountList }) => {
  const { navStyle, themeType, locale, pathname, navOpen, sidebarKeys, menuState } = settings;
  const { name } = PlanId;
  const { details } = PlanDetails;
  return {
    navStyle,
    themeType,
    locale,
    pathname,
    name,
    details,
    navOpen,
    cloudType,
    sidebarKeys,
    settings,
    cloudAccountList
  };
};

const mapDispatchToProps = dispatch => ({
  toggleCollapsedSideNav: (val) => dispatch(toggleCollapsedSideNav(val)),
  switchLanguage: (language) => dispatch(switchLanguage(language)),
  updateSidebarKeys: (keys) => dispatch({
    type: 'UPDATE_SIDEBAR_KEYS',
    payload: keys
  }),
  dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar));
