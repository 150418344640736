import React, { Component } from "react";
import Layout from "antd/es/layout";
import Sidebar from "../Sidebar/index";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";
import Topbar from "../Topbar/index";
import App from "routes/index";
import { connect } from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { getWhiteLabelCopyrightName } from "util/whitelabel";

const { Content, Footer } = Layout;

export class MainApp extends Component {
  getContainerClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return "";
    }
  }

  getNavStyles = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return <Topbar />;
      case NAV_STYLE_DRAWER:
        return <Topbar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return null;
    }
  }

  getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <Sidebar />;
      case NAV_STYLE_DRAWER:
        return <Sidebar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />;
      default:
        return null;
    }
  }

  render() {
    const { match, width, navStyle } = this.props;

    return (
      <Layout className="gx-app-layout font-poppins !p-0">
        {this.getSidebar(navStyle, width)}
        <Layout>
          {this.getNavStyles(navStyle)}
          <Content
            className={`gx-layout-content ${this.getContainerClass(navStyle)} `}
          >
            <App match={match} />
            <Footer className="w-full relative bg-white py-2">
              <div 
                className="absolute inset-0 w-full h-full opacity-65"
                style={{
                  backgroundImage: 'url("https://images.icompaas.com/i/dot-bg.svg")',
                  // backgroundRepeat: 'no-repeat',
                  // backgroundSize: 'cover',
                  // backgroundPosition: 'center',
                  // transform: 'scale(2.5)',
                }}
              />
              <div className="w-full mx-auto px-4 relative z-10">
                <div className="flex flex-col items-center justify-center gap-1">
                  {/* Policies Section */}
                  <div className="flex items-center space-x-4">
                    <div className="flex items-center space-x-4">
                      <a 
                        href="https://www.icompaas.com/terms-of-use" 
                        target="_blank" 
                        className="text-gray-600 hover:text-blue-500 transition-colors duration-200 text-xs"
                      >
                        Terms Of Use
                      </a>
                      <a 
                        href="https://www.icompaas.com/privacy-policy" 
                        target="_blank" 
                        className="text-gray-600 hover:text-blue-500 transition-colors duration-200 text-xs"
                      >
                        Privacy Policy
                      </a>
                      <a 
                        href="https://www.icompaas.com/cookie-policy" 
                        className="text-gray-600 hover:text-blue-500 transition-colors duration-200 text-xs"
                      >
                        Cookie Policy
                      </a>
                    </div>
                  </div>

                  {/* Copyright */}
                  <div className="text-center text-gray-400 text-[10px]">
                    <p>{`Copyright © ${new Date().getFullYear()} All Rights Reserved by iCompaas LLC.`}</p>
                  </div>
                </div>
              </div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { width, navStyle } = settings;
  return { width, navStyle };
}

export default connect(mapStateToProps)(MainApp);
