import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Microsoft365Details from './Microsoft365Details';
import './employeeOnboarding.css';

const IntegrationCard = ({ title, icon, description, status, isComingSoon, onClick, isActive }) => (
    <button
        onClick={onClick}
        className={`relative group bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 flex items-center border ${
            isActive 
                ? 'border-2 border-blue-500 ring-2 ring-blue-200' 
                : 'border-gray-200 hover:border-blue-500'
        } w-full text-left`}
        disabled={isComingSoon}
    >
        <div className="flex-shrink-0">
            <img src={icon} className="h-12 w-12" alt={`${title} icon`} />
        </div>
        <div className="ml-6 flex-1">
            <h3 className={`text-[17px] font-medium ${isActive ? 'text-blue-600' : 'text-gray-900'}`}>
                {title}
            </h3>
            <p className="mt-2 text-[13px] text-gray-500">{description}</p>
        </div>
        {isComingSoon && (
            <div className="absolute -top-2 right-4">
                <span className="bg-gray-700 text-white text-xs px-2 py-1 rounded animate-bounce">
                    Coming Soon
                </span>
            </div>
        )}
        {isActive && (
            <div className="absolute -top-2 right-4">
                <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded">
                    Selected
                </span>
            </div>
        )}
    </button>
);

const getTitleForStep = (step, integrationType = 'google') => {
    const titles = {
        google: {
            'step1': 'Open Admin Console',
            'step2': 'Access API Controls',
            'step3': 'Configure New App',
            'step4': 'Find iCompaas App',
            'step5': 'Change Access Settings',
            'step6': 'Set App to Trusted'
        },
        microsoft: {
            'step1': 'Integrate with Microsoft 365',
            'step2': 'Enter Credentials',
            'step3': 'Accept Permissions'
        }
    };
    return titles[integrationType][step];
};

const TableOfContents = ({ activeSection, onSectionClick, completedSteps, integrationType = 'google' }) => {
    const steps = integrationType === 'google' 
        ? ['step1', 'step2', 'step3', 'step4', 'step5', 'step6']
        : ['step1', 'step2', 'step3', 'step4', 'step5'];

    return (
        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
            <h4 className="text-sm font-medium text-gray-900 mb-4">Onboarding Help</h4>
            <nav className="space-y-1">
                <a
                    href="#overview"
                    onClick={(e) => {
                        e.preventDefault();
                        onSectionClick('overview');
                    }}
                    className={`block px-3 py-2 text-sm rounded-md ${
                        activeSection === 'overview'
                            ? 'bg-blue-50 text-blue-700'
                            : 'text-gray-600 hover:bg-gray-50'
                    }`}
                >
                    Overview
                </a>
                {steps.map((step) => (
                    <a
                        key={step}
                        href={`#${step}`}
                        onClick={(e) => {
                            e.preventDefault();
                            onSectionClick(step);
                        }}
                        className={`flex items-center px-3 py-2 text-sm rounded-md ${
                            activeSection === step
                                ? 'bg-blue-50 text-blue-700'
                                : 'text-gray-600 hover:bg-gray-50'
                        }`}
                    >
                        {completedSteps[step] && (
                            <svg
                                className="w-4 h-4 mr-2 text-green-500"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 13l4 4L19 7"
                                />
                            </svg>
                        )}
                        {getTitleForStep(step, integrationType)}
                    </a>
                ))}
            </nav>
        </div>
    );
};

const GoogleWorkspaceDetails = ({
    onSectionVisible,
    onConnectionSuccess,
    completedSteps,
    onStepComplete
}) => {
    const [collapsedSteps, setCollapsedSteps] = useState({
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        step6: false
    });

    const handleStepComplete = (stepId) => {
        onStepComplete(stepId);
        setCollapsedSteps(prev => ({
            ...prev,
            [stepId]: true
        }));

        // Get the next step ID
        const steps = ['step1', 'step2', 'step3', 'step4', 'step5', 'step6'];
        const currentIndex = steps.indexOf(stepId);
        const nextStepId = steps[currentIndex + 1];

        // If there's a next step, scroll to it
        if (nextStepId) {
            setTimeout(() => {
                const nextElement = document.getElementById(nextStepId);
                if (nextElement) {
                    nextElement.scrollIntoView({ 
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }, 300); // Small delay to allow collapse animation to start
        }
    };

    const toggleStep = (stepId) => {
        if (completedSteps[stepId]) {
            setCollapsedSteps(prev => ({
                ...prev,
                [stepId]: !prev[stepId]
            }));
        }
    };

    // Add check for all steps completed
    const allStepsCompleted = Object.values(completedSteps).every(step => step === true);

    // Modify each step div to include the completed button and collapsible functionality
    const StepContent = ({ stepId, title, children }) => (
        <div id={stepId} className="border-b border-gray-200 pb-6">
            <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleStep(stepId)}
            >
                <h4 className="text-lg font-medium text-gray-900 mb-3">
                    {title}
                    {completedSteps[stepId] && (
                        <i className="fas fa-check-circle text-green-500 ml-2" />
                    )}
                </h4>
                {completedSteps[stepId] && (
                    <svg
                        className={`w-5 h-5 text-gray-500 transition-transform duration-300 ${collapsedSteps[stepId] ? '' : 'transform rotate-180'}`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                )}
            </div>

            <div className={`transition-all duration-300 ${collapsedSteps[stepId] ? 'h-0 overflow-hidden' : ''}`}>
                {children}
                {!completedSteps[stepId] && (
                    <div className="flex justify-end mt-4">
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleStepComplete(stepId);
                            }}
                            className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                        >
                            <span className="text-sm">Mark as Completed</span>
                            <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTestingConnection, setIsTestingConnection] = useState(false);
    const [testSuccess, setTestSuccess] = useState(false);
    const [isDoneDisabled, setIsDoneDisabled] = useState(false);
    const [domain, setDomain] = useState('N/A');

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Extract the section id from the element
                        const sectionId = entry.target.id;
                        onSectionVisible(sectionId);
                    }
                });
            },
            {
                rootMargin: '-20% 0px -70% 0px'
            }
        );

        // Observe all section elements
        const sections = ['overview', 'step1', 'step2', 'step3', 'step4', 'step5', 'step6'];
        sections.forEach(section => {
            const element = document.getElementById(section);
            if (element) {
                observer.observe(element);
            }
        });

        return () => {
            sections.forEach(section => {
                const element = document.getElementById(section);
                if (element) {
                    observer.unobserve(element);
                }
            });
        };
    }, [onSectionVisible]);

    const handleDoneClick = () => {
        setIsModalOpen(true);
    };

    const handleTestConnection = () => {
        setIsTestingConnection(true);

        // Simulate API call with setTimeout
        setTimeout(() => {
            setTestSuccess(true);
            setIsTestingConnection(false);
            setIsDoneDisabled(true);
            setDomain('icompaas.com');
            onConnectionSuccess(); // Call parent callback to update status

            // Close modal and scroll to top after showing success for 1.5 seconds
            setTimeout(() => {
                setIsModalOpen(false);
                // Get the status panel element and scroll to it
                const statusPanel = document.querySelector('[data-testid="status-panel"]');
                if (statusPanel) {
                    statusPanel.scrollIntoView();
                }
            }, 1500);
        }, 4000);
    };

    return (
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <div id="overview">
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                    Google Workspace Integration with iCompaas
                </h3>
                <p className="text-gray-600 mb-6">
                Integrating the IDP - Google Workspace with iCompaas ensures streamlined compliance automation for personnel and helps with reporting for your organization. This guide walks you through accessing the Google Workspace console, configuring API controls, and creating a "Trusted Relationship" with your IDP.
                </p>
                <div className="bg-yellow-50 border-l-4 border-yellow-400 p-2">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="ml-2">
                            <p className="text-sm text-yellow-700">
                                <span className="font-bold">Must Read: </span>
                                Please complete all steps in this guide to ensure proper integration. Each step is essential for establishing a secure connection between iCompaas and your Google Workspace environment.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="space-y-8">
                    <StepContent
                        stepId="step1"
                        title="Step 1. Open Google Workspace Admin Console:"
                    >
                        <p className="text-gray-600 mb-4">
                            Go to <a href="https://admin.google.com/ac/owl" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">https://admin.google.com/ac/owl</a> and sign in with your admin account
                        </p>
                    </StepContent>

                    <StepContent
                        stepId="step2"
                        title="Step 2. Access API Controls:"
                    >
                        <p className="text-gray-600 mb-4">
                            In the left-hand menu, navigate to <span className="font-medium">Security</span> &gt; <span className="font-medium">Access and data control</span> &gt; <span className="font-medium">API Controls</span>, then select <span className="font-medium">Manage third-party app access</span>.
                        </p>
                        <img
                            src="https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/62033849724/original/LWc-wrkB3CtHh8DDUnMLbR2CJj46Yhding.png?1734111580"
                            alt="Google API Controls Navigation"
                            className="rounded-lg border border-gray-200 shadow-sm w-full"
                        />
                    </StepContent>

                    <StepContent
                        stepId="step3"
                        title="Step 3. Click on Configure new app"
                    >
                        <img
                            src="https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/62033849955/original/mR0bOpEshOQlOUoPZ3UBmjdjzN8YWkhD0g.png?1734112492"
                            alt="Configure new app button location"
                            className="rounded-lg border border-gray-200 shadow-sm w-full mb-4"
                        />
                    </StepContent>

                    <StepContent
                        stepId="step4"
                        title="Step 4. Find the iCompaas Compliance App:"
                    >
                        <p className="text-gray-600 mb-4">
                            Search for <span className="font-medium">iCompaas Compliance Web App</span> and select it.
                        </p>
                        <img
                            src="https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/62033849761/original/vZAaYkUW696wg9PFbPOs44Q8oDB9i6_2mA.png?1734111728"
                            alt="Search for iCompaas Compliance Web App"
                            className="rounded-lg border border-gray-200 shadow-sm w-full"
                        />
                    </StepContent>

                    <StepContent
                        stepId="step5"
                        title="Step 5. Change Access Settings:"
                    >
                        <p className="text-gray-600 mb-4">
                            Under <span className="font-medium">Scope</span>, you have two options:
                        </p>
                        <ul className="list-disc list-inside text-gray-600 space-y-2 mb-4">
                            <li>
                                Choose <span className="font-medium">All in &lt;Account Name&gt;(all users)</span>, which allows us to monitor all users
                            </li>
                            <li className="flex items-center">
                                <span className="mr-2">OR</span>
                            </li>
                            <li>
                                Choose <span className="font-medium">Select Org units</span>, if in case only set of users to monitor under specific Org Unit
                            </li>
                        </ul>
                        <img
                            src="https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/62033849469/original/noMNNA9f3HQL2v6siBMo27BWm6J4K08LrQ.png?1734110503"
                            alt="Google Workspace Access Settings"
                            className="rounded-lg border border-gray-200 shadow-sm w-full"
                        />
                    </StepContent>

                    <StepContent
                        stepId="step6"
                        title="Step 6. Set the App to Trusted:"
                    >
                        <p className="text-gray-600 mb-4">
                            Confirm that the iCompaas Compliance Web App is set to <span className="font-medium">Trusted</span>.
                        </p>
                        <img
                            src="https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/62033849484/original/5nA_B_mrWo2fR-yCHjaBFraDuif_rTyS7Q.png?1734110557"
                            alt="Set iCompaas app to Trusted"
                            className="rounded-lg border border-gray-200 shadow-sm w-full mb-6"
                        />
                    </StepContent>

                    {allStepsCompleted && (
                        <div className="bg-blue-50 p-6 rounded-lg mt-8 border border-blue-100">
                            <h4 className="text-lg font-medium text-blue-900 mb-2">
                                Congratulations!
                            </h4>
                            <p className="text-blue-700 mb-6">
                                You have successfully created a "Trust Relationship" between your Cloud account and iCompaas". iCompaas can securely access only necessary Read-Only metadata to help with compliance automation and reporting for your organization.
                            </p>
                            
                            <div className="flex justify-center">
                                <button
                                    onClick={handleDoneClick}
                                    disabled={isDoneDisabled}
                                    className={`inline-flex items-center justify-center px-6 py-3 rounded-lg transition-all duration-300 transform ${
                                        isDoneDisabled
                                            ? 'bg-gray-400 text-white cursor-not-allowed'
                                            : 'bg-[#FF9900] text-white hover:bg-[#FFB84D] hover:scale-105 hover:shadow-lg'
                                    }`}
                                >
                                    <span className="text-sm font-medium mr-2">Completed, Click to Test Integration</span>
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl">
                        {testSuccess ? (
                            <div className="text-center">
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <svg className="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <h3 className="mt-4 text-lg font-medium text-gray-900">Connection Successful!</h3>
                                <p className="mt-2 text-sm text-gray-600">
                                    Your Google Workspace integration is now active.
                                </p>
                            </div>
                        ) : (
                            <>
                                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                                    Verify Integration
                                </h3>
                                <p className="text-gray-600 mb-6">
                                    Click the button below to test your Google Workspace integration connection.
                                </p>
                                <div className="flex flex-col gap-4">
                                    <button
                                        onClick={handleTestConnection}
                                        disabled={isTestingConnection}
                                        className="w-full inline-flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:bg-blue-400"
                                    >
                                        {isTestingConnection ? (
                                            <>
                                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                Testing connection...
                                            </>
                                        ) : (
                                            <>
                                                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                                </svg>
                                                Test my connection
                                            </>
                                        )}
                                    </button>
                                    <button
                                        onClick={() => setIsModalOpen(false)}
                                        className="w-full inline-flex items-center justify-center px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const StatusPanel = ({ isActive, domain = 'N/A', integrationType = 'google' }) => {
    const [lastRetrievalDate, setLastRetrievalDate] = useState(null);
    const [isRetrieving, setIsRetrieving] = useState(false);
    const [showAddAccount, setShowAddAccount] = useState(false);
    const [showPersonnelListLink, setShowPersonnelListLink] = useState(false);

    const integrationConfig = {
        google: {
            title: 'Google Workspace Integration',
            icon: '/google.svg',
            buttonText: 'Add another Google Workspace account'
        },
        microsoft: {
            title: 'Microsoft 365 Integration',
            icon: '/microsoft.svg',
            buttonText: 'Add another Microsoft 365 account'
        }
    };

    const config = integrationConfig[integrationType];

    const handleRetrieveClick = () => {
        setIsRetrieving(true);
        setTimeout(() => {
            setIsRetrieving(false);
            setLastRetrievalDate(new Date());
            setShowAddAccount(true);
            setShowPersonnelListLink(true);
        }, 5000);
    };

    return (
        <>
            <div className="flex flex-col gap-3" data-testid="status-panel">
                <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                    {/* Title and Icon */}
                    <div className="flex items-center space-x-2 mb-3">
                        <img src={config.icon} alt={config.title} className="w-6 h-6" />
                        <h3 className="text-lg font-medium text-gray-900">{config.title}</h3>
                    </div>

                    {/* Status Bar with all elements */}
                    <div className="flex items-center space-x-4">
                        <div className="flex items-center space-x-2">
                            <span className="text-sm text-gray-500">Domain:</span>
                            <span className="text-sm font-medium text-gray-900">{domain}</span>
                        </div>

                        <div className="flex items-center space-x-2">
                            <span className="text-sm max-sm:!ml-[-16px] text-gray-500">Status:</span>
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                            }`}>
                                <span className={`w-2 h-2 mr-1 rounded-full ${
                                    isActive ? 'bg-green-500' : 'bg-red-500'
                                }`}></span>
                                {isActive ? 'Active' : 'Inactive'}
                            </span>
                        </div>

                        <button
                            onClick={handleRetrieveClick}
                            disabled={!isActive || isRetrieving}
                            className={`inline-flex items-center px-3 max-sm:!ml-[-1px] py-1.5 text-sm rounded-md transition-all duration-300 ${
                                isActive
                                    ? isRetrieving
                                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                        : 'bg-blue-50 text-blue-700 hover:bg-blue-100'
                                    : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                            }`}
                        >
                            {isRetrieving ? (
                                <>
                                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Retrieving...
                                </>
                            ) : (
                                'Retrieve Personnel List'
                            )}
                        </button>

                        {showPersonnelListLink && (
                            <div className="flex items-center space-x-2">
                                <NavLink
                                    to={{
                                        pathname: `/account/personnellist/${domain}`,
                                        state: { lastRetrievalDate }
                                    }}
                                    className="text-sm text-blue-600 hover:text-blue-800 hover:underline"
                                >
                                    View Personnel List
                                </NavLink>
                            </div>
                        )}

                        {lastRetrievalDate && (
                            <span className="text-sm text-gray-500">
                                Last Synced: {lastRetrievalDate.toLocaleString()}
                            </span>
                        )}
                    </div>
                </div>

                {showAddAccount && (
                    <button className="w-[400px] mb-2 py-2 px-4 rounded-lg border border-blue-300 flex items-center justify-center gap-2 text-white hover:bg-blue-600 transition-colors bg-blue-500">
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                        </svg>
                        {config.buttonText}
                    </button>
                )}
            </div>
        </>
    );
};

const IntegrationCards = ({ integrations, selectedIntegration, onSelect }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    const handleTouchStart = (e) => {
        setTouchStart(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > 50;
        const isRightSwipe = distance < -50;

        if (isLeftSwipe && currentIndex < integrations.length - 1) {
            setCurrentIndex(prev => prev + 1);
        }
        if (isRightSwipe && currentIndex > 0) {
            setCurrentIndex(prev => prev - 1);
        }

        setTouchStart(null);
        setTouchEnd(null);
    };

    return (
        <div className="integration-cards-container">
            <div 
                className="integration-cards-wrapper"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                {integrations.map((integration, index) => (
                    <div key={integration.id} className="integration-card">
                        <IntegrationCard
                            {...integration}
                            isActive={selectedIntegration === integration.id}
                            onClick={() => onSelect(integration.id)}
                        />
                    </div>
                ))}
            </div>
            <div className="card-indicators">
                {integrations.map((_, index) => (
                    <div
                        key={index}
                        className={`card-indicator ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    />
                ))}
            </div>
        </div>
    );
};

const EmployeeOnboarding = () => {
    const [activeSection, setActiveSection] = useState('overview');
    const [isIntegrationActive, setIsIntegrationActive] = useState(false);
    const [domain, setDomain] = useState('N/A');
    const [completedSteps, setCompletedSteps] = useState({
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        step6: false
    });

    // Get integration from URL query parameter or default to 'google'
    const queryParams = new URLSearchParams(window.location.search);
    const integrationParam = queryParams.get('integration');
    
    // Set default to 'google' if no integration parameter is provided
    const [selectedIntegration, setSelectedIntegration] = useState(integrationParam || 'google');

    // Add useEffect to handle URL update if needed
    useEffect(() => {
        if (!integrationParam) {
            // Update URL to include default integration
            const newUrl = `${window.location.pathname}?integration=google`;
            window.history.replaceState({}, '', newUrl);
        }
    }, [integrationParam]);

    // Add handleStepComplete function
    const handleStepComplete = (stepId) => {
        setCompletedSteps(prev => ({
            ...prev,
            [stepId]: true
        }));
    };

    const handleConnectionSuccess = (newDomain) => {
        setIsIntegrationActive(true);
        setDomain(newDomain);
    };

    const integrations = [
        {
            id: 'google',
            title: 'Google Workspace',
            icon: '/google.svg',
            description: 'Integrate with Google Workspace to manage user accounts, access, and permissions',
            status: 'Available',
            isComingSoon: false
        },
        {
            id: 'microsoft',
            title: 'Microsoft 365',
            icon: '/microsoft.svg',
            description: 'Manage Microsoft 365 users, groups, and licenses',
            status: 'Available',
            isComingSoon: false
        },
        {
            id: 'zoho',
            title: 'Zoho Workplace',
            icon: '/zoho.svg',
            description: 'Manage Zoho Workplace users, groups, and access controls',
            status: 'Coming Soon',
            isComingSoon: true
        }
    ];

    const handleSectionClick = (section) => {
        setActiveSection(section);
        const element = document.getElementById(section);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleIntegrationSelect = (integrationId) => {
        setSelectedIntegration(integrationId);
        // Update URL to reflect selected integration
        const newUrl = `${window.location.pathname}?integration=${integrationId}`;
        window.history.pushState({}, '', newUrl);
        // Reset states when switching integrations
        setIsIntegrationActive(false);
        setDomain('N/A');
        setCompletedSteps({
            step1: false,
            step2: false,
            step3: false,
            step4: false,
            step5: false,
            step6: false
        });
        setActiveSection('overview');
    };

    return (
        <div className="flex flex-col justify-start min-h-screen bg-gray-50 py-4 px-4 sm:px-6 lg:px-8">
            <div className="w-full">
                <div className="text-center mb-8">
                    <h2 className="text-3xl font-semibold text-gray-900">
                        Personnel Onboarding
                    </h2>
                    <p className="mt-2 text-sm w-[760px] mx-auto text-gray-600">
                    This page will help you in integrating with your “Identity Provider(IDP)” like Google Workspace, Microsoft 365, and more coming soon. This will streamline personnel onboarding process for the organization and help with the “People part of the Compliance”.
                    </p>
                </div>

                <div className="flex flex-col lg:flex-row gap-3">
                    <div className="lg:w-72 space-y-4">
                        <IntegrationCards
                            integrations={integrations}
                            selectedIntegration={selectedIntegration}
                            onSelect={handleIntegrationSelect}
                        />
                    </div>

                    <div className="flex-1 max-w-[calc(100%-336px)]">
                        {selectedIntegration === 'google' ? (
                            <div className="flex flex-col">
                                <StatusPanel
                                    isActive={isIntegrationActive}
                                    domain={domain}
                                    integrationType={selectedIntegration}
                                />
                                <div className="flex gap-8">
                                    <div className="flex-1">
                                        <GoogleWorkspaceDetails
                                            onSectionVisible={setActiveSection}
                                            onConnectionSuccess={() => handleConnectionSuccess('icompaas')}
                                            completedSteps={completedSteps}
                                            onStepComplete={handleStepComplete}
                                        />
                                    </div>
                                    <div className="lg:w-56">
                                        <TableOfContents
                                            activeSection={activeSection}
                                            onSectionClick={handleSectionClick}
                                            completedSteps={completedSteps}
                                            integrationType={selectedIntegration}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : selectedIntegration === 'microsoft' ? (
                            <div className="flex flex-col">
                                <StatusPanel
                                    isActive={isIntegrationActive}
                                    domain={domain}
                                    integrationType={selectedIntegration}
                                />
                                <div className="flex gap-8">
                                    <div className="flex-1">
                                        <Microsoft365Details
                                            onSectionVisible={setActiveSection}
                                            onConnectionSuccess={() => handleConnectionSuccess('icompaas')}
                                            completedSteps={completedSteps}
                                            onStepComplete={handleStepComplete}
                                        />
                                    </div>
                                    <div className="lg:w-56">
                                        <TableOfContents
                                            activeSection={activeSection}
                                            onSectionClick={handleSectionClick}
                                            completedSteps={completedSteps}
                                            integrationType={selectedIntegration}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-center justify-center h-64 bg-white rounded-lg border border-gray-200 p-8">
                                <p className="text-gray-500 text-center">
                                    Select an integration from the sidebar to view its details
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeOnboarding; 