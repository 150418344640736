import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Compliance = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/`}
      component={asyncComponent(() => import("./src/index"))}
    />
    <Route
      path={`${match.url}/summary`}
      component={asyncComponent(() => import("./summary/src/index"))}
    />
    <Route
      path={`${match.url}/compliancemanagement/personnel`}
      component={asyncComponent(() => import("./complianceManagement/src/personnel"))}
    />
    <Route
      path={`${match.url}/compliancemanagement/vendors`}
      component={asyncComponent(() => import("./complianceManagement/src/vendors"))}
    />
    <Route
      path={`${match.url}/compliancemanagement/devicemanagement/devices`}
      component={asyncComponent(() => import("./complianceManagement/deviceManagement/src/devices"))}
    />
        <Route
      path={`${match.url}/compliancemanagement/devicemanagement/deviceauthorization`}
      component={asyncComponent(() => import("./complianceManagement/deviceManagement/src/deviceAuthorization"))}
    />
     <Route
      path={`${match.url}/compliancemanagement/roles`}
      component={asyncComponent(() => import("./complianceManagement/src/roles"))}
    />
       <Route
      path={`${match.url}/compliancemanagement/questionnaire`}
      component={asyncComponent(() => import("./complianceManagement/src/questionnaire"))}
    />
   
  </Switch>
);

export default Compliance;
