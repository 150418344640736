import React from 'react';
import { NavLink } from 'react-router-dom';
import CisImage from "assets/images/CIS.jpg";
import AwsWaImage from "assets/images/AWS_WA_ICON_2_BLUE.png";

const SecurityTabs = ({ currentPath, purchasedAddons, handleTabClick, cloudType }) => {
  const securityTabs = [
    {

      //added security posture tab to the security section.
      id: "securityposture",
      title: "Security Posture",
      path: "/portal/compliance/security",
      icon: "/cyber-security.svg",
      showForCloud: ["aws", "azure"]
    },
    {
      id: "cislevel1",
      title: "CIS Level 1",
      path: "/portal/compliance/cislevel1",
      icon: CisImage,
      showForCloud: ["aws"]
    },
    {
      id: "cislevel2",
      title: "CIS Level 2",
      path: "/portal/compliance/cislevel2",
      icon: CisImage,
      showForCloud: ["aws"]
    },
    {
      id: "awswellarch",
      title: "AWS Well Architected",
      path: "/portal/compliance/awswellarch",
      icon: AwsWaImage,
      showForCloud: ["aws"]
    }
  ];

  const purchasedSecurityTabs = securityTabs
    .filter(tab => tab.showForCloud.includes(cloudType))
    .filter(tab => {
      if (tab.id === 'securityposture') return true;
      return purchasedAddons.some(addon => {
        if (tab.id === 'cislevel1' || tab.id === 'cislevel2') {
          return addon.feature_id === 'cis' || addon.feature_id === tab.id;
        }
        return addon.feature_id === tab.id;
      });
    });

  return (
    <div className="fancy-tab-container font-poppins">
      <div className="fancy-tabs">
        {purchasedSecurityTabs.map((menu) => (
          <div
            className={`tab-item ${currentPath === menu.path ? 'active' : ''}`}
            key={menu.title}
          >
            <NavLink
              to={menu.path}
              className="tab-link"
              onClick={() => handleTabClick(menu.path)}
            >
              <span className="tab-icon">
                <img
                  src={menu.icon}
                  alt={menu.title}
                  className="custom-tab-icon"
                  style={{ width: '28px', height: '28px' }}
                />
              </span>
              <span className="tab-title">{menu.title}</span>
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SecurityTabs; 